/* Portfolio Filter Menu */
.filter-menu {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 12px 20px;
  background: #149ddd;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
  max-width: 800px;
}

/* Filter Header */
.filter-menu__header {
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.filter-menu__header i {
  font-size: 16px;
  color: #fff;
}

/* Add a container for buttons */
.filter-menu__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}

/* Filter Buttons */
.filter-menu__button {
  padding: 4px 12px;
  font-size: 11px;
  border: 1px solid;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  border-radius: 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: white;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

/* Button Variants */
.filter-menu__button--all {
  color: black;
  border-color: white;
}

.filter-menu__button--ongoing {
  color: #34c759;
  border-color: #34c759;
}

.filter-menu__button--works {
  color: #ffb74d;
  border-color: #ffb74d;
}

.filter-menu__button--past {
  color: #ff6b6b;
  border-color: #ff6b6b;
}

.filter-menu__button--ideas {
  color: #8e8e93;
  border-color: #8e8e93;
}

/* Active States */
.filter-menu__button--all.active {
  background: black;
  color: white;
  border-color: white;
}

.filter-menu__button--ongoing.active {
  background: #34c759;
  color: white;
  border-color: white;
}

.filter-menu__button--works.active {
  background: #ffb74d;
  color: white;
  border-color: white;
}

.filter-menu__button--past.active {
  background: #ff6b6b;
  color: white;
  border-color: white;
}

.filter-menu__button--ideas.active {
  background: #8e8e93;
  color: white;
  border-color: white;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .filter-menu {
    padding: 10px;
    gap: 12px;
  }

  .filter-menu__buttons {
    gap: 8px;
  }

  .filter-menu__button {
    padding: 3px 10px;
    font-size: 11px;
    height: 20px;
    min-width: 70px;
  }
}

/* Modern Magazine-Style Layout */
.portfolio-page {
  padding: 0;
  background: #fff;
}

.portfolio-page .container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0px 15px;
}

.portfolio-page h2 {
  font-size: 36px;
  font-weight: 700;
  color: #173b6c;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 20px;
}

.portfolio-page h2:after {
  content: '';
  position: absolute;
  display: block;
  width: 250px;
  height: 4px;
  background: #149ddd;
  bottom: 0;
  left: 0;
}

.portfolio-page p {
  margin-bottom: 20px;
  line-height: 1.7;
  color: #444;
  font-size: 16px;
}

.portfolio-item {
  margin-bottom: 32px;
  opacity: 0.4;
  transition: opacity 0.3s ease;
}

.portfolio-item.show {
  opacity: 1;
}

/* Portfolio Card - same layout for all screens */
.portfolio-card {
  display: flex;
  gap: 24px;
  padding: 24px;
  background: #f8fafc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.portfolio-card:hover {
  transform: translateX(8px);
  background: #f8fafc;
  border-color: #d1d5db;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

/* Thumbnail */
.portfolio-image {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
}

.portfolio-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Content */
.portfolio-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Meta tags above title */
.portfolio-meta {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  order: -1;
}

/* Base badge styles with higher specificity */
.portfolio-page .meta-tag,
.project-hero .status-badge > span,
.project-template .status-badge > span {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 10px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 100px;
  text-transform: lowercase;
  background: rgba(71, 85, 105, 0.1);
  color: #475569;
}

/* Status colors with higher specificity */
.portfolio-page .meta-tag.status.works,
.project-hero .status-badge .works,
.project-template .status-badge .works { 
  background: rgba(14, 165, 233, 0.15);
  color: #0ea5e9;
}

.portfolio-page .meta-tag.status.ongoing,
.project-hero .status-badge .ongoing { 
  background: rgba(16, 185, 129, 0.15);
  color: #10b981;
}

.portfolio-page .meta-tag.status.past,
.project-hero .status-badge .past { 
  background: rgba(255, 109, 109, 0.15);
  color: #ff6b6b;
}

.portfolio-page .meta-tag.status.ideas,
.project-hero .status-badge .ideas { 
  background: rgba(245, 158, 11, 0.15);
  color: #f59e0b;
}

/* Badge container styles */
.portfolio-meta,
.status-badge {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.portfolio-title {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

/* Portfolio excerpt text */
.portfolio-card .portfolio-excerpt {
  color: #666;
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.view-project {
  font-size: 14px;
  font-weight: 500;
  color: #149ddd;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

/* Mobile adjustments */
@media (max-width: 640px) {
  .portfolio-card {
    padding: 16px;
    gap: 16px;
  }

  .portfolio-image {
    width: 80px;
    height: 80px;
  }

  .meta-tag,
  .status-badge > span {
    font-size: 11px;
    padding: 3px 10px;
    height: 22px;
  }

  .portfolio-title {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .portfolio-card .portfolio-excerpt {
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 12px;
  }

  .view-project {
    font-size: 13px;
  }
}

/* Even smaller screens */
@media (max-width: 380px) {
  .portfolio-card {
    grid-template-columns: 100px 1fr;
  }

  .portfolio-image {
    width: 100px;
    height: 100px;
  }
}

/* Add these project-specific styles */

/* Project Details Page */
.project-details {
  padding-top: 80px;
}

.project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.back-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 50px;
  background: #149ddd;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-btn:hover {
  background: #117ab0;
  transform: translateY(-2px);
}

/* Project Modal */
.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  height: 90vh;
  background: #fff;
  border-radius: 12px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.modal-fixed-header {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px 12px 0 0;
}

.modal-fixed-header h2 {
  margin: 0;
  color: #173b6c;
  font-size: 24px;
}

.modal-scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 30px;
}

.modal-fixed-footer {
  padding: 20px 30px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  border-radius: 0 0 12px 12px;
}

/* Section Styles */
.modal-scrollable-content section {
  margin-bottom: 40px;
}

.modal-scrollable-content h3 {
  color: #149ddd;
  margin-bottom: 20px;
  font-size: 20px;
}

/* Overview Grid */
.overview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.overview-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.overview-item .label {
  color: #666;
  font-size: 14px;
}

.overview-item .value {
  font-weight: 600;
  color: #173b6c;
}

/* Logo circle style */
.toggle-nav {
  display: flex;
  align-items: center;
  gap: 12px;
  background: none;
  border: none;
  font-size: 14px;
  color: #475569;
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease;
}

.toggle-nav i {
  font-size: 20px;
  width: 36px;
  height: 36px;
  background: rgba(20, 157, 221, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #149ddd;
  transition: all 0.3s ease;
}

.toggle-nav:hover i {
  background: rgba(20, 157, 221, 0.2);
}

.toggle-nav.expanded i {
  background: #149ddd;
  color: white;
}