.main-content {
  width: 100%;
  padding-left: 80px; /* Increased padding for better spacing from panel */
  padding-right: 20px; /* Added right padding for balance */
  position: relative;
}

/* Add overlay for when panel is expanded */
.main-content::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Show overlay when panel is expanded */
.main-content.panel-expanded::before {
  opacity: 1;
  visibility: visible;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .main-content {
    padding-left: 57px; /* Slightly more space on mobile */
    padding-right: 0px;
  }
} 