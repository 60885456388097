.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  margin-bottom: 15px;
}

/* Hero */
.project-hero {
  padding: 20px 0;
  background: linear-gradient(to bottom, #f8fafc, #fff);
  margin-top: 0;
}

.hero-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 24px;
}

.hero-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
  text-align: left;
}

.hero-thumbnail {
  width: 150px;
}

.hero-thumbnail img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.1);
}

/* Status badge layout only */
.status-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

/* Remove dots completely */
.status-badge .dot {
  display: none !important;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .status-badge {
    justify-content: center;
    gap: 6px;
  }
}

.project-hero h1 {
  font-size: 36px;
  font-weight: 700;
  color: #0f172a;
  margin-bottom: 12px;
  line-height: 1.2;
}

.project-hero .tagline {
  font-size: 18px;
  color: #64748b;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Overview */
.project-overview {
  padding: 20px 0;
}

.overview-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.overview-text {
  font-size: 18px;
  line-height: 1.7;
  color: #475569;
}

.overview-text p {
  margin-bottom: 32px;
}

.cta-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #0ea5e9;
  font-weight: 500;
  text-decoration: none;
  transition: gap 0.3s ease;
}

.cta-link:hover {
  gap: 12px;
}

.overview-image {
  max-width: 300px;
  margin-left: auto;
}

.overview-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.1);
}

/* Motivation & Solution */
.motivation-solution {
  padding: 20px 0;
  background: #f8fafc;
}

.cs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.cs-item {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
}

.cs-label {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: #0ea5e9;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
}

.cs-item p {
  font-size: 14px;
  line-height: 1.5;
  color: #475569;
}

/* Stats */
.key-stats {
  padding: 20px 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  max-width: 800px;
  margin: 0 auto;
}

.stat-item {
  text-align: center;
}

.stat-value {
  font-size: 24px;
  font-weight: 600;
  color: #0ea5e9;
  line-height: 1;
  margin-bottom: 4px;
}

.stat-label {
  font-size: 12px;
  color: #64748b;
  font-weight: 400;
}

/* Features */
.key-features {
  padding: 20px 0;
  background: #f8fafc;
}

.key-features h2,
.project-gallery h2 {
  font-size: 24px;
  font-weight: 700;
  color: #0f172a;
  text-align: center;
  margin-bottom: 20px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}

.feature-card {
  padding: 15px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-4px);
}

/* Update header layout */
.feature-card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.feature-card i {
  font-size: 20px;
  color: #0ea5e9;
  flex-shrink: 0;
  /* Remove margin-bottom since it's now inline */
}

.feature-card h3 {
  font-size: 18px;
  font-weight: 600;
  color: #0f172a;
  margin: 0; /* Remove margin since it's now inline */
}

.feature-card p {
  font-size: 14px;
  line-height: 1.4;
  color: #475569;
  padding-left: 32px; /* Align with title */
}

/* Gallery */
.project-gallery {
  padding: 20px 0;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  grid-auto-rows: 1fr;
}

.gallery-item {
  aspect-ratio: 4/3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  background: #f1f5f9;
  position: relative;
}

.gallery-item:hover {
  transform: scale(1.02);
}

.gallery-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@media (max-width: 992px) {
  .overview-content {
    grid-template-columns: 1fr;
  }
  
  .overview-image {
    max-width: 250px;
    margin: 0 auto;
  }
  
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cs-grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .cs-item {
    padding: 15px;
  }

  .hero-grid {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .hero-thumbnail {
    width: 150px;
    margin: 0 auto 20px;
  }

  .status-badge {
    justify-content: center;
    gap: 6px;
  }

  .gallery-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 20px;
    max-width: 400px;
  }

  .stat-value {
    font-size: 20px;
  }

  .stat-label {
    font-size: 11px;
  }
}

@media (max-width: 576px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 0 15px;
  }
}

/* Project Navigator */
.project-navigator {
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
  border-bottom: 1px solid #e2e8f0;
  transition: all 0.3s ease;
}

.navigator-header {
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  background: #efeff0;
}

.toggle-nav {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 12px;
  border: none;
  background: #149ddd;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.toggle-nav:hover {
  background: #117ab0;
}

.toggle-nav i {
  font-size: 14px;
  color: #fff;
}

.navigator-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 12px;
  padding: 16px;
  max-height: 300px;
  overflow-y: auto;
  background: #f8fafc;
}

.project-card {
  display: flex;
  gap: 8px;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.project-card:hover {
  transform: translateY(-4px);
}

.project-card.active {
  border: 2px solid #0ea5e9;
}

.project-card-image {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  position: relative;
}

.project-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.project-card-content {
  flex: 1;
  min-width: 0;
}

.project-card-content h3 {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-card-content p {
  font-size: 11px;
  color: #64748b;
  margin: 2px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Resources */
.project-resources {
  padding: 20px 0;
  background: #f8fafc;
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}

.resource-card {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;
}

.resource-card:hover {
  transform: translateY(-4px);
}

.resource-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f5f9;
  border-radius: 12px;
  color: #0ea5e9;
  font-size: 20px;
  flex-shrink: 0;
}

.resource-content h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: #0f172a;
}

.resource-content p {
  font-size: 14px;
  color: #64748b;
  margin: 0;
}

@media (max-width: 768px) {
  .resources-grid {
    grid-template-columns: 1fr;
  }
  
  .resource-card {
    padding: 15px;
  }
}