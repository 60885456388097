/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #272829;
}

/* Header */
#header {
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  width: 300px;
  transition: all ease-in-out 0.5s;
  z-index: 9997;
  padding: 0 15px;
  background: #040b14;
  overflow-y: auto;
  overflow-x: hidden;
}

#header.mobile-nav-active {
  left: 0;
}

/* Profile section */
.profile {
  padding: 0;
  text-align: center;
  position: relative;
}

.profile img {
  margin: 15px auto 15px;
  display: block;
  width: 120px;
  height: 120px;
  border: 8px solid #2c2f3f;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  position: relative;
}

.profile h1 {
  font-size: 24px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.profile h1 a {
  color: #fff;
  text-decoration: none;
}

.profile .location {
  color: #a8a9b4;
  font-size: 14px;
  text-align: center;
  margin: -5px 0 15px 0;
}

/* Navigation Menu */
.nav-menu {
  padding-bottom: 30px;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu a {
  display: flex;
  align-items: center;
  color: #a8a9b4;
  padding: 7px 15px;
  margin-bottom: 4px;
  transition: 0.3s;
  font-size: 15px;
  text-decoration: none;
}

.nav-menu a i {
  font-size: 24px;
  padding-right: 8px;
  color: #6f7180;
}

.nav-menu a:hover,
.nav-menu .active {
  text-decoration: none;
  color: #fff;
}

.nav-menu a:hover i,
.nav-menu .active i {
  color: #149ddd;
}

/* Social Links */
.social-links {
  padding-bottom: 20px;
  text-align: center;
}

.social-links a {
  font-size: 18px;
  display: inline-block;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.social-links a:hover {
  background: #149ddd;
  color: #fff;
}

.social-links .adventure-link {
  background: #212431;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 36px;
  line-height: 1;
  margin-right: 4px;
  padding: 8px 0;
  text-align: center;
  transition: .3s;
  width: 36px;
  border: none;
  cursor: pointer;
}

.social-links .adventure-link:hover {
  background: #149ddd;
  color: #fff;
}

.social-links .adventure-link i {
  transition: transform 0.3s ease;
}

.social-links .adventure-link:hover i {
  transform: rotate(45deg);
}

/* Hero Section */
#hero {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

#hero:before {
  content: "";
  background: rgba(5, 13, 24, 0.3);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

#hero .hero-container {
  position: relative;
  z-index: 2;
  min-width: 300px;
  padding: 100px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}

#hero h1 {
  margin: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  text-align: center;
}

#hero p {
  color: #fff;
  margin: 15px 0 0 0;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

/* Hero text styles */
#hero p span {
  color: #fff;
  padding-bottom: 4px;
  letter-spacing: 1px;
  border-bottom: 4px solid #149ddd;
}
/* Hero Buttons */
.hero-buttons {
  margin-top: 100px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.hero-btn {
  min-width: 200px; /* Set fixed minimum width */
  padding: 15px 30px;
  border: 2px solid #149ddd;
  background: rgba(20, 157, 221, 0.1);
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: grid;
  grid-template-columns: 40px 1fr; /* Fixed width for icon column */
  align-items: center;
  backdrop-filter: blur(5px);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hero-btn i {
  font-size: 20px;
  transition: transform 0.3s ease;
  justify-self: center; /* Center the icon in its column */
}

.hero-btn span {
  text-align: center; /* Align text to the left */
}

.hero-btn:hover {
  background: rgba(20, 157, 221, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(20, 157, 221, 0.2);
}

.hero-btn:hover i {
  transform: rotate(15deg);
}

.about-btn {
  border-color: #149ddd;
}

.projects-btn {
  border-color: #149ddd;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .hero-buttons {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .hero-btn {
    width: 220px; /* Fixed width on mobile */
    padding: 12px 25px;
    font-size: 14px;
  }
}

/* Main Content */
.main {
  margin-left: 300px;
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9999;
  border: 0;
  background: #149ddd;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mobile-nav-toggle i {
  font-size: 28px;
  transition: transform 0.3s ease-in-out;
}

.mobile-nav-toggle i.bi-x {
  transform: rotate(180deg);
}

/* Responsive */
@media (max-width: 1199px) {
  .main {
    margin-left: 0;
  }
  
  .mobile-nav-toggle {
    display: flex;
  }
}

/* Desktop styles */
@media (min-width: 1200px) {
  #header {
    left: 0;
  }
  
  .main {
    margin-left: 300px;
  }
  
  .mobile-nav-toggle {
    display: none;
  }
}

/* About Page Styles */
.about-page {
  padding: 60px 0;
  overflow: hidden;
}

.about-page .container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.about-page h2 {
  font-size: 36px;
  font-weight: 700;
  color: #173b6c;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 20px;
}

.about-page h2:after {
  content: '';
  position: absolute;
  display: block;
  width: 250px;
  height: 4px;
  background: #149ddd;
  bottom: 0;
  left: 0;
}

.about-page p {
  margin-bottom: 20px;
  line-height: 1.7;
  color: #444;
  font-size: 16px;
}

.about-page blockquote {
  padding: 20px 25px;
  background-color: rgba(20, 157, 221, 0.05);
  border-left: 3px solid #149ddd;
  margin: 25px 0;
  border-radius: 4px;
}

.about-page blockquote p {
  font-style: italic;
  margin-bottom: 5px;
  font-size: 14px;
  color: #2c3e50;
  line-height: 1.0;
}

.about-page .blockquote-footer {
  padding-left: 20px;
  font-size: 12px;
  color: #666;
  font-style: normal;
}

.about-page .image-quote-container {
  float: left;
  width: 450px;
  margin: 0 30px 20px 0;
}

.about-page .profile-image {
  width: 100%;
  margin-bottom: 10px;
}

.about-page .profile-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-page .image-quote-container {
    float: none;
    width: 100%;
    max-width: 350px;
    margin: 0 auto 30px;
  }
  
  .about-page h2 {
    text-align: center;
  }
  
  .about-page h2:after {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Project Modal */
.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  height: 90vh;
  background: #fff;
  border-radius: 12px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.modal-fixed-header {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px 12px 0 0;
}

.modal-fixed-header h2 {
  margin: 0;
  color: #173b6c;
  font-size: 24px;
}

.modal-scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 30px;
}

.modal-fixed-footer {
  padding: 20px 30px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  border-radius: 0 0 12px 12px;
}

/* Section Styles */
.modal-scrollable-content section {
  margin-bottom: 40px;
}

.modal-scrollable-content h3 {
  color: #149ddd;
  margin-bottom: 20px;
  font-size: 20px;
}

/* Overview Grid */
.overview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.overview-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.overview-item .label {
  color: #666;
  font-size: 14px;
}

.overview-item .value {
  font-weight: 600;
  color: #173b6c;
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.stat-item {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 25px 20px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(20, 157, 221, 0.1);
}

.stat-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(20, 157, 221, 0.1);
  border-color: #149ddd;
}

.stat-value {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
  margin-bottom: 8px;
}

.stat-value .number {
  font-size: 24px;
  font-weight: 700;
  color: #149ddd;
}

.stat-value .unit {
  font-size: 14px;
  color: #666;
  margin-left: 2px;
}

.stat-label {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.gallery-item {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.05);
}

.gallery-grid img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

/* Overview Status */
.overview-item .status {
  display: inline-flex;
  padding: 4px 12px;
  border-radius: 50px;
  font-size: 12px;
  background: #fff;
  border: 1px solid #ddd;
}

.overview-item .status.ongoing {
  background: #fff;
  color: #149ddd;
  border-color: #149ddd;
}

/* Resources List */
.resources-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.resource-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  background: #f8f9fa;
  border-radius: 8px;
  color: #173b6c;
  text-decoration: none;
  transition: all 0.3s ease;
}

.resource-link:hover {
  background: #149ddd;
  color: #fff;
}

.close-btn {
  padding: 8px 20px;
  border: none;
  border-radius: 50px;
  background: #149ddd;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-btn:hover {
  background: #117ab0;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .modal-content {
    width: 95%;
    height: 95vh;
  }
  
  .modal-fixed-header h2 {
    font-size: 20px;
  }
  
  .modal-scrollable-content {
    padding: 20px;
  }
}

/* Update More Details button style */
.details-btn {
  margin-top: 20px;
  padding: 10px 24px;
  background: rgba(255, 255, 255, 0.9);
  color: #149ddd;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.details-btn:hover {
  background: #fff;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Image Modal - Basic Implementation */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
}

.image-modal img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-modal {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 10000;
}

/* Keep the shared status styles */
.status {
  display: inline-flex;
  padding: 4px 12px;
  border-radius: 50px;
  font-size: 12px;
  background: #fff;
  border: 1px solid #ddd;
}

/* Footer Styles */
.footer {
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 1;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.copyright {
  font-size: 14px;
  color: #6c757d;
}

.contact-link {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  color: #149ddd;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.contact-link:hover {
  color: #fff;
  background: #149ddd;
  transform: translateY(-1px);
}

.contact-link i {
  font-size: 16px;
}

@media (max-width: 1199px) {
  .contact-link:hover {
    background: none;
    color: #149ddd;
    transform: none;
  }
}

.concepts-subheading {
  text-align: center;
  color: #666;
  font-size: 18px;
  margin: 20px 0;
  font-style: italic;
}

.concepts-subheading .nav-link {
  color: #149ddd;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: inherit;
  font-style: inherit;
}

.concepts-subheading .nav-link:hover {
  color: #117ab0;
  text-decoration: underline;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.social-links.pulse a {
  animation: pulse 0.5s ease-in-out 2;
}

/* Header Additional Styles */
.profile .location {
  color: #a8a9b4;
  font-size: 14px;
  text-align: center;
  margin: -5px 0 15px 0;
}

.menu-footer {
  padding: 20px 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  max-height: calc(100vh - 400px);  /* Leave space for nav menu */
  overflow-y: auto;                 /* Enable scrolling */
  position: relative;               /* Contain the scrolling */
  padding-bottom: 60px;  /* Add extra padding at bottom */
}

.menu-footer h3 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.current-work {
  margin-bottom: 20px;
}

.work-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.work-link {
  background: none;
  border: none;
  color: #a8a9b4;
  font-size: 13px;
  text-align: left;
  padding: 4px 0;
  cursor: pointer;
  transition: color 0.3s ease;
}

.work-link:hover {
  color: #149ddd;
}

.favorites {
  margin-bottom: 20px;
}

.favorite-activities {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Force two columns */
  gap: 8px;
  padding: 0 15px;
}

.activity-tag {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;  /* Fill column width */
  position: relative;  /* For z-index */
  z-index: 1;         /* Base z-index */
}

.activity-content {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;
  color: #a8a9b4;
}

.activity-tag i {
  color: #149ddd;
  font-size: 14px;
}

.activity-label {
  font-size: 12px;
  white-space: nowrap;
}

.activity-description {
  font-size: 12px;
  margin-left: 4px;
  color: #a8a9b4;
  display: inline;
}

.activity-tag.expanded {
  grid-column: 1 / -1;  /* Span from first to last column */
  background: rgba(20, 157, 221, 0.1);
  z-index: 2;         /* Higher z-index when expanded */
}

.activity-tag:hover {
  background: rgba(20, 157, 221, 0.15);
}

/* Remove old tooltip styles */

/* Menu Footer Base Styles */
.menu-footer {
  padding: 20px 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  max-height: calc(100vh - 400px);  /* Leave space for nav menu */
  overflow-y: auto;                 /* Enable scrolling */
  position: relative;               /* Contain the scrolling */
  padding-bottom: 60px;  /* Add extra padding at bottom */
}

/* Collapsible Sections */
.collapsible-section {
  margin-bottom: 15px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}

.section-content {
  display: block;
  height: auto;
  opacity: 1;
  transition: all 0.3s ease;
}

/* Work Links */
.work-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.work-link {
  background: none;
  border: none;
  color: #149ddd;
  font-size: 14px;
  text-align: left;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s ease;
}

.work-link:hover {
  color: #fff;
}

.work-description {
  color: #a8a9b4;
  font-size: 12px;
  margin: 4px 0 0 0;
}

/* Favorites/Activities */
.favorite-activities {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* Force two columns */
  gap: 8px;
  padding: 0 15px;
}

.activity-tag {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;  /* Fill column width */
  position: relative;  /* For z-index */
  z-index: 1;         /* Base z-index */
}

.activity-content {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;
  color: #a8a9b4;
}

.activity-tag i {
  color: #149ddd;
  font-size: 14px;
}

.activity-label {
  font-size: 12px;
  white-space: nowrap;
}

.activity-description {
  font-size: 12px;
  margin-left: 4px;
  color: #a8a9b4;
  display: inline;
}

.activity-tag.expanded {
  grid-column: 1 / -1;  /* Span from first to last column */
  background: rgba(20, 157, 221, 0.1);
  z-index: 2;         /* Higher z-index when expanded */
}

.activity-tag:hover {
  background: rgba(20, 157, 221, 0.15);
}

/* Small height screens */
@media screen and (max-height: 800px) {
  .menu-footer {
    position: relative;
    padding: 10px 15px;
  }

  .section-header {
    cursor: pointer;
    margin-bottom: 0;
    padding: 8px 0;
  }

  .section-header i {
    display: inline-block;
    font-size: 12px;
  }

  .section-content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  .section-content.expanded {
    height: auto;
    opacity: 1;
    padding-top: 10px;
    margin-bottom: 10px;
  }
}

/* Profile Tooltip */
.profile-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(20, 157, 221, 0.95);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  width: max-content;
  max-width: 200px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, visibility 0.3s;
  text-align: center;
}

.profile img:hover + .profile-tooltip,
.profile-tooltip:hover {
  visibility: visible;
  opacity: 1;
}

/* Update About section link styles - More specific selectors */
.about-page p a,
.about-page .content a {
  color: #149ddd !important;
  font-weight: 500;
  text-decoration: none !important;
  position: relative;
  transition: all 0.3s ease;
  padding-bottom: 2px;
  display: inline-block;
}

.about-page p a:hover,
.about-page .content a:hover {
  color: #117ab0 !important;
  text-decoration: none !important;
}

/* Remove any conflicting styles */
#about a {
  all: unset; /* Reset any existing styles */
  cursor: pointer;
}

/* Tippy Tooltip Styles */
.tippy-box {
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.4;
  padding: 0;
  text-align: center;
  max-width: 350px !important; /* Fixed max-width */
}

.tippy-content {
  padding: 0;
}

.tooltip-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip-thumbnail-container {
  position: relative;
  margin: -16px -16px 12px;
  cursor: pointer;
  overflow: hidden;
  max-width: 100%;
  width: calc(100% + 32px);
}

.tooltip-thumbnail {
  width: 100%;
  height: 160px;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnail-overlay i {
  color: white;
  font-size: 24px;
}

.tooltip-thumbnail-container:hover .tooltip-thumbnail {
  transform: scale(1.05);
}

.tooltip-thumbnail-container:hover .thumbnail-overlay {
  opacity: 1;
}

.reference-term {
  color: inherit;
  border-bottom: 2px solid rgba(20, 157, 221, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
}

.reference-term:hover {
  border-bottom-color: rgba(20, 157, 221, 0.8);
}

.tooltip-actions {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.website-link,
.view-project-link {
  color: #149ddd;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  transition: color 0.3s ease;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  text-align: left;
  width: 100%;
}

.website-link:hover,
.view-project-link:hover {
  color: #117ab0;
}

.website-link i,
.view-project-link i {
  font-size: 14px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .tippy-box {
    max-width: 300px !important;
  }
  
  .tooltip-thumbnail-container {
    max-width: 200px;
  }
  
  .tooltip-thumbnail {
    height: 120px;
  }
}

/* Adventure Contact Styles */
.contact-adventure-btn {
  background: none;
  border: none;
  color: #a8a9b4;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 7px 15px;
  transition: all 0.3s ease;
  width: 100%;
  text-align: left;
}

.contact-adventure-btn i {
  color: #6f7180;
  font-size: 24px;
  padding-right: 8px;
  transition: transform 0.3s ease;
}

.contact-adventure-btn:hover {
  color: #fff;
}

.contact-adventure-btn:hover i {
  color: #149ddd;
  transform: rotate(45deg);
}

/* Compass Animation */
@keyframes compass-point {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-30deg); }
  75% { transform: rotate(30deg); }
  100% { transform: rotate(0deg); }
}

.compass-active i {
  animation: compass-point 2s ease-in-out infinite;
}

/* Footprints Trail */
.footprint {
  position: absolute;
  opacity: 0;
  font-size: 12px;
  color: #149ddd;
  animation: fade-in-out 1s ease-in-out forwards;
}

@keyframes fade-in-out {
  0% { opacity: 0; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(0.8); }
}

/* Contact Adventure Modal - Retro Adventure Theme */
.contact-adventure-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
  font-family: 'Courier New', monospace;
}

.adventure-content {
  background: #1a1a1a;
  border: 2px solid #149ddd;
  border-radius: 0;
  width: 90%;
  max-width: 600px;
  padding: 30px;
  position: relative;
  box-shadow: 0 0 20px rgba(20, 157, 221, 0.3),
              inset 0 0 20px rgba(20, 157, 221, 0.2);
  color: #fff;
}

.adventure-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  border-bottom: 1px solid #149ddd;
  padding-bottom: 15px;
}

.back-btn,
.close-btn {
  background: none;
  border: 1px solid #149ddd;
  color: #149ddd;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  transition: all 0.3s ease;
  font-family: 'Courier New', monospace;
}

.back-btn:hover,
.close-btn:hover {
  background: #149ddd;
  color: #000;
  transform: translateY(-2px);
}

.back-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #666;
  color: #fff;
}

.step-text {
  font-size: 20px;
  color: #149ddd;
  margin-bottom: 30px;
  text-align: left;
  line-height: 1.6;
  position: relative;
  padding-left: 20px;
}

.step-text::before {
  content: ">";
  position: absolute;
  left: 0;
  color: #149ddd;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

.choice-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
}

.choice-btn {
  background: transparent;
  border: 1px solid #149ddd;
  color: #149ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 16px 20px;
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: 'Courier New', monospace;
  text-align: left;
  position: relative;
}

.choice-btn::before {
  content: "[";
  position: absolute;
  left: 8px;
  opacity: 0;
  transition: all 0.3s ease;
}

.choice-btn::after {
  content: "]";
  position: absolute;
  right: 8px;
  opacity: 0;
  transition: all 0.3s ease;
}

.choice-btn:hover {
  background: rgba(20, 157, 221, 0.1);
  padding-left: 25px;
  padding-right: 25px;
}

.choice-btn:hover::before,
.choice-btn:hover::after {
  opacity: 1;
}

.choice-btn i {
  font-size: 20px;
  color: #149ddd;
  transition: transform 0.3s ease;
}

.choice-btn:hover i {
  transform: scale(1.2);
}

/* Add retro scanlines effect */
.adventure-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 0, 0, 0.1) 50%
  );
  background-size: 100% 4px;
  pointer-events: none;
  opacity: 0.1;
}

/* Add CRT screen effect */
@keyframes flicker {
  0% { opacity: 0.97; }
  5% { opacity: 0.95; }
  10% { opacity: 0.9; }
  15% { opacity: 0.95; }
  20% { opacity: 0.98; }
  25% { opacity: 0.95; }
  30% { opacity: 0.9; }
  35% { opacity: 0.95; }
  40% { opacity: 0.98; }
  45% { opacity: 0.95; }
  50% { opacity: 0.9; }
  55% { opacity: 0.95; }
  60% { opacity: 0.98; }
  65% { opacity: 0.95; }
  70% { opacity: 0.9; }
  75% { opacity: 0.95; }
  80% { opacity: 0.98; }
  85% { opacity: 0.95; }
  90% { opacity: 0.9; }
  95% { opacity: 0.95; }
  100% { opacity: 0.98; }
}

.adventure-content {
  animation: flicker 30s infinite;
}

.project-details {
  padding-top: 80px;
}

.project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.back-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 50px;
  background: #149ddd;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-btn:hover {
  background: #117ab0;
  color: #fff;
  transform: translateY(-2px);
}

/* Add this to override smooth scrolling for project pages */
.project-page {
  scroll-behavior: auto;
}

/* Add these styles */
.about-page,
.portfolio-page {
  padding: 2rem 0;
  min-height: calc(100vh - 60px); /* Adjust based on your navigation height */
}

.main-nav {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.main-nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-nav li {
  margin: 0 1rem;
}

.main-nav a {
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: #333;
}

.main-nav a.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}